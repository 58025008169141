<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay  v-if="authUser.warehouse_id > 0">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="ratingEntry.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                      <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="ratingEntry.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                      </ValidationProvider>
                  </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="District"  rules="required|min_value:1">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="district_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="ratingEntry.district_id"
                              :options="districtList"
                              id="district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Upazila"  rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="upazilla_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="ratingEntry.upazila_id"
                                    :options="upazilaList"
                                    id="upazilla_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Union" rules="required|min_value:1">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('org_pro_union.union')"
                            label-for="union_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="ratingEntry.union_id"
                              :options="unionList"
                              id="union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Farmer ID" vid='farmer_id' rules='required'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('warehouse_service_performance.farmer_id')"
                                label-for="farmer_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.farmer_id')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="ratingEntry.farmer_id"
                            :options="warehouseFarmerList"
                            id="farmer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Farmer Name" vid='farmer_name'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('warehouseFarmer.farmer_name')"
                                label-for="farmer_name"
                                slot-scope="{ valid, errors }"
                            >
                            <b-form-input
                            id="farmer_name"
                            v-model="ratingEntry.farmer_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            disabled ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Service Rate" vid='service_rate' rules='required'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="service_rate"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.wh_service_rate')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="service_rate"
                            v-model="ratingEntry.service_rate"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Wh Officer" vid='officer_id' rules='required'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="officer_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.wh_officer')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="officer_id"
                            v-model="ratingEntry.officer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Wh Office Rate" vid='officer_rate' rules='required'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="officer_rate"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.wh_ofc_rate')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-input
                                id="officer_rate"
                                v-model="ratingEntry.officer_rate"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
             <b-overlay v-if="authUser.warehouse_id === 0 || authUser.warehouse_id == null">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fiscal_year_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="ratingEntry.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                      <ValidationProvider name="Division" vid='division_id' rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="division"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="ratingEntry.division_id"
                          :options="divisionList"
                          id="division_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                      </ValidationProvider>
                  </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="District"  rules="required|min_value:1">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="district_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="ratingEntry.district_id"
                              :options="districtList"
                              id="district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Upazila"  rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="upazilla_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="ratingEntry.upazila_id"
                                    :options="upazilaList"
                                    id="upazilla_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Union" rules="required|min_value:1">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('org_pro_union.union')"
                            label-for="union_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="ratingEntry.union_id"
                              :options="unionList"
                              id="union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <!-- <ValidationProvider name="Farmer ID" vid='farmer_id' rules='required'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('warehouse_service_performance.farmer_id')"
                                label-for="farmer_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.farmer_id')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="farmer_id"
                            v-model="ratingEntry.farmer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider> -->
                            <ValidationProvider name="Warehouse Name" vid="warehouse_name" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="warehouse_name"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{$t('warehouse_config.warehouse_name')}} <span class="text-danger">*</span>
                        </template>
                          <b-form-select
                            plain
                            v-model="ratingEntry.warehouse_id"
                            :options="warehouseNameList"
                            id="warehouse_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Farmer Name" vid='farmer_name'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('warehouseFarmer.farmer_name')"
                                label-for="farmer_name"
                                slot-scope="{ valid, errors }"
                            >
                             <b-form-select
                            plain
                            v-model="ratingEntry.farmer_id"
                            :options="warehouseFarmerList"
                            id="farmer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Service Rate" vid='service_rate' rules='required|max_value:10'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="service_rate"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.wh_service_rate')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="service_rate"
                            v-model="ratingEntry.service_rate"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Warehouse Store Keeper" vid='officer_id' rules='required'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="officer_id"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.wh_officer')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="officer_id"
                            v-model="ratingEntry.officer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Warehouse Store Keeper Rate" vid='officer_rate' rules='required|max_value:10'>
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="officer_rate"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('warehouse_service_performance.wh_ofc_rate')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-input
                                id="officer_rate"
                                v-model="ratingEntry.officer_rate"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                      </b-col>
                    </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { ratingEntry } from '../../api/routes'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFiscalData()
      this.document = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      ratingEntry: {
        fiscal_year_id: 0,
        id: '',
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        union_id: 0,
        region_id: 0,
        farmer_id: 0,
        service_rate: '',
        officer_id: '',
        officer_rate: '',
        farmer_name: 0,
        warehouse_id: 0
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      wareList: [],
      warehouseFarmerList: [],
      warehouseNameList: []
    }
  },
  computed: {
    authUser () {
      const authUser = this.$store.state.Auth.authUser
      if (authUser.warehouse_id > 0) {
        const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.value === authUser.warehouse_id)
        this.dataSet(warehouseList[0])
      }
      return authUser
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fiscalList: function () {
         return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
     },
    regionLists: function () {
      return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'ratingEntry.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'ratingEntry.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'ratingEntry.upazila_id': function (newVal, oldVal) {
      this.unionList = this.getUnion(newVal)
    },
    'ratingEntry.union_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal)
    },
    'ratingEntry.warehouse_id': function (newVal, oldVal) {
      this.dList = this.getFarmerInfoList(newVal)
    }
  },
  methods: {
    getFiscalData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id && item.status === 0)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      let result = null
      const loadinState = { loading: false, listReload: true }

      result = await RestApi.postData(warehouseServiceBaseUrl, ratingEntry, this.ratingEntry)
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
      getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnion (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getFarmerInfoList (wareID) {
        RestApi.getData(authServiceBaseUrl, '/user/farmer-list').then(response => {
        if (response) {
            const dataObject = response.data.filter(item => item.warehouse_id === wareID)
            this.warehouseFarmerList = dataObject.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.mobile_no, text: obj.name_bn }
                } else {
                    return { value: obj.mobile_no, text: obj.name }
                }
            })
          }
        })
    },
    dataSet (warehouseData) {
      this.ratingEntry.division_id = warehouseData.division_id
      this.ratingEntry.district_id = warehouseData.district_id
      this.ratingEntry.upazila_id = warehouseData.upazilla_id
      this.ratingEntry.union_id = warehouseData.union_id
      RestApi.getData(authServiceBaseUrl, '/user/farmer-list').then(response => {
      if (response) {
          const dataObject = response.data.filter(item => item.warehouse_id === warehouseData.value)
          this.warehouseFarmerList = dataObject.map(obj => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.username, text: obj.name_bn }
              } else {
                  return { value: obj.username, text: obj.name }
              }
          })
        }
      })
      return true
    },
    getWarehouseName (unionID) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      if (unionID) {
        return warehouseList.filter(item => item.union_id === unionID)
      }
      return warehouseList
    }
  }
}

</script>
