
<template>
  <b-container fluid>
    <!-- <iq-card> -->
      <!-- <template v-slot:body>
       <b-row>
      <b-col>
        <b-form-group
          class="row"
          label-cols-sm="4"
          :label="$t('warehouse_config.fiscal_year')"
          label-for="year"
          >
          <b-form-select
            plain
            v-model="search.year"
            :options="fiscalList"
            id="year"
            >
            <template v-slot:first>
            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
      </b-col>
    </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template> -->
      <!-- <template v-slot:body>
        <b-overlay  v-if="authUser.warehouse_id > 0">
        <b-row>
          <b-col>
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
                >
                <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseNameList"
                  id="warehouse_id"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                </b-form-group>
          </b-col>
          <b-col>
          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
        </b-overlay>
        <b-overlay v-if="authUser.warehouse_id === 0 || authUser.warehouse_id == null">
        <b-row>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('warehouse_config.region')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.region_id"
              :options="regionLists"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        </b-row>
        <b-row>
            <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.district_id"
              :options="regionDistrictLists"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        </b-row>
        <b-row>
          <b-col>
              <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="warehouse_id"
                        :label="$t('warehouse_information.warehouse_name')"
                        >
                        <b-form-select
                          plain
                          v-model="search.warehouse_id"
                          :options="warehouseNameList"
                          id="warehouse_id"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        </b-form-group>
          </b-col>
          <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
        </b-overlay>
      </template> -->
    <!-- </iq-card> -->
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service_performance.ratio') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="listReload">
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table thead-class="table_head" striped bordered hover :items="ratingDataList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                  <template v-slot:cell(year)="data">
                   {{ $n(data.item.year.split('-')[0], { useGrouping: false })}}-{{$n(data.item.year.split('-')[1], { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(index)="data">
                    {{ $n(data.index + pagination.slOffset) }}
                  </template>
<!--                  <template v-slot:cell(officer_id)="data">-->
<!--                    {{ $n(data.item.officer_id) }}-->
<!--                  </template>-->
                  <template v-slot:cell(farmer_id)="data">
                    {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.farmer_id, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(service_rate)="data">
                    {{ $n(data.item.service_rate) }}
                  </template>
                  <template v-slot:cell(officer_rate)="data">
                    {{ $n(data.item.officer_rate) }}
                  </template>
                </b-table>
                <b-pagination
                  align="center"
                  v-if="pagination.totalRows"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                  />
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" ok-title="Close" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>

  </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { ratingList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        year: 0,
        warehouse_id: 0,
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0
      },
      complainData: {
        divText: '',
        divText_bn: '',
        disText: '',
        disText_bn: '',
        upzText: '',
        upzText_bn: '',
        regionText: '',
        regionText_bn: '',
        wareHouseText: '',
        wareHouseText_bn: ''
      },
      ratingDataList: [],
      farmerList: [],
      warehouseNameList: [],
      regionDistrictLists: []
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    regionLists: function () {
      return this.$store.state.warehouse.regionList
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('warehouse_service_performance.ratio') : this.$t('warehouse_config.fiscal_year_update')
    },
    fiscalList: function () {
         return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('warehouse_information.division'), class: 'text-center' },
          { label: this.$t('warehouse_information.district'), class: 'text-center' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-center' },
          { label: this.$t('warehouse_config.fiscal_year'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.farmer_id'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.wh_officer'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.wh_ofc_rate'), class: 'text-center' },
          { label: this.$t('warehouse_service_performance.wh_service_rate'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'year' },
          { key: 'farmer_id' },
          { key: 'officer_id' },
          { key: 'officer_rate' },
          { key: 'service_rate' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'year' },
          { key: 'farmer_id' },
          { key: 'officer_id' },
          { key: 'officer_rate' },
          { key: 'service_rate' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  async created () {
    this.loadData()
  },
   watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getDistrictList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
      this.regionDistrictLists = this.getRegionDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
     this.warehouseNameList = this.getWarehouseName(newVal)
    }
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        region_id: '',
        district_id: '',
        upazilla_id: '',
        union_id: '',
        warehouse_name: '',
        warehouse_name_bn: '',
        file_name: '',
        file_name_bn: '',
        total_capacity: '',
        warehouse_type_id: '',
        remarks: ''
      }
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(authServiceBaseUrl, '/user/farmer-details').then(response => {
        if (response.success) {
          this.farmerList = response.data
          RestApi.getData(warehouseServiceBaseUrl, ratingList, params).then(response => {
            if (response.success) {
              this.ratingDataList = this.ListShow(response.data.data)
              this.paginationData(response.data)
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            } else {
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
          })
         }
        })
    },
    ListShow (data) {
      let tmpData = {}
      let tmpData2 = {}
      let tmpUpzila = {}
      const listData = data.map(item => {
        tmpData = this.$store.state.commonObj.divisionList.find(orgItem => orgItem.value === item.division_id && orgItem.status === 0)
        tmpData2 = this.$store.state.commonObj.districtList.find(orgItem => orgItem.value === item.district_id && orgItem.status === 0)
        tmpUpzila = this.$store.state.commonObj.upazilaList.find(orgItem => orgItem.value === item.upazila_id && orgItem.status === 0)
        if (tmpData) {
          this.complainData.divText = tmpData.text
          this.complainData.divText_bn = tmpData.text_bn
        }
        if (tmpData2) {
          this.complainData.disText = tmpData2.text
          this.complainData.disText_bn = tmpData2.text_bn
        }
        if (tmpUpzila) {
          this.complainData.upzText = tmpUpzila.text
          this.complainData.upzText_bn = tmpUpzila.text_bn
        }
        const orgData = { division_name: this.complainData.divText, division_name_bn: this.complainData.divText_bn, distict_name: this.complainData.disText, distict_name_bn: this.complainData.disText_bn, upzila_name: this.complainData.upzText, upzila_name_bn: this.complainData.upzText_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
      getDistrictList (divisionId = null) {
      const regionDistrictLists = this.$store.state.commonObj.districtList

      if (divisionId) {
        return regionDistrictLists.filter(district => district.division_id === divisionId)
      }

      return regionDistrictLists
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList

      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getWarehouseName (upazilaID) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList

      if (upazilaID) {
        return warehouseList.filter(document => document.upazilla_id === upazilaID)
      }

      return warehouseList
    },
    getWarehouseNameSearch (ID) {
      const data = this.$store.state.warehouse.warehouseInfoList
      if (ID) {
        const sds = data.filter(item => item.value === ID)
        this.search.warehouse_id = sds[0].value
        this.warehouseNameList = sds
      }
    },
     getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
            const dataObject = response.filter(document => document.region_id === regionId)
            this.regionDistrictLists = dataObject.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                } else {
                    return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                }
            })
        }
      })
    }
  }
}
</script>
